@import 'variables';
@import 'light-inputs';
@import 'icons';
@import "mixins";
@import 'lightning-catalogue.scss';
/**GLOBAL STYLES**/

button:focus,
input:focus,
select:focus {
  outline: 1;
}

html.cdk-global-scrollblock {
  width: initial;
  position: initial;
  overflow-y: initial;
}

body.modal-open {
  overflow: hidden;
}

body.modal-open .cdk-global-overlay-wrapper {
  pointer-events: all;
  display: block !important;
  overflow: auto;
  padding: 30px 0;
  position: fixed;
}

.theme--default body{
  background-attachment: fixed !important;
}
.cdk-overlay-backdrop {
  position: fixed !important;
}

.mat-mdc-dialog-container {
  margin: 30px auto 30px !important;
  height: auto !important;
  overflow: auto !important;
  max-height: 90vh;
}

.mat-mdc-dialog-content {
  max-width: none !important;
  max-height: none !important;
  overflow: initial !important;
}

@media screen and (-ms-high-contrast: active) {

  button:focus,
  input:focus,
  select:focus {
    outline: 1;

    @include themedNoEncapsulation() {
      border: 1px solid t('colour-b') !important;
    }
  }

  button,
  input,
  select {
    @include themedNoEncapsulation() {
      border: 1px solid t('colour-b') !important;
    }
  }

  .mat-mdc-radio-button.mat-primary.mat-mdc-radio-checked .mdc-radio__outer-circle {
    border: 3px dotted #1aebff;
  }
}

@for $i from 1 through 10 {
  .light-col-#{$i*10} {
    width: $i * 10%;
  }
}

@for $i from 1 through 10 {
  .light-padding-#{$i*10} {
    padding: $i * 10px;
  }
}

.light-center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.light-disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}

.light-invisible {
  visibility: hidden;
}

.light-center {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.light-block {
  display: block;
  width: 100%;
}

.light-flex {
  display: flex;
}

.light-row {
  min-width: 100%;
  margin: 0 -10px;
}

.light-right {
  text-align: right;
  justify-content: flex-end;
}

.light-fat--note {
  font-size: 11px;
}

.light-fat {
  font-weight: bold !important;
}

.light-btn {
  padding: 10px 20px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  transition-duration: 0.3s;

  &:disabled {
    opacity: 0.7;
  }
}

.light-label-fix {
  margin-top: 22px;
  line-height: 0;
}

.light-btn-xs {
  padding: 5px 10px;
  font-size: 12px;
}

.more-btn {
  border-radius: 3px;
}

.search-btn {
  border-radius: 3px;
  transition-duration: .3s;

  &:hover {
    text-decoration: none;
    box-shadow: hsla(0, 0%, 100%, .8) 0 0 0 .25rem;
  }
}

.light-btn-primary {
  @include themedNoEncapsulation() {
    background-color: t('light-primary');
  }

  @include themedNoEncapsulation() {
    color: t('colour-button-text');
  }

  &:hover {
    @include themedNoEncapsulation() {
      background-color: lighten(t('light-primary'), 10%);
    }
  }
}

.light-btn-fancy {
  background-color: #b36200;

  @include themedNoEncapsulation() {
    color: t('colour-button-text');
  }

  font-weight: 600;

  @include themedNoEncapsulation() {
    box-shadow: 0px 0px 2px darken(t('light-fancy'), 20%);
  }

  &:hover {
    @include themedNoEncapsulation() {
      box-shadow: 0px 0px 2px darken(t('light-primary'), 20%);
    }

    @include themedNoEncapsulation() {
      background-color: t('light-primary');
    }
  }
}

.light-btn-transparent {
  @include themedNoEncapsulation() {
    background: t('light-transparent');
  }

  @include themedNoEncapsulation() {
    color: t('colour-b');
  }

  &:hover,
  &:active,
  &:focus {
    @include themedNoEncapsulation() {
      background: t('light-transparent-darken');
    }
  }
}

.light-btn-ico {
  @include themedNoEncapsulation() {
    box-shadow: 0 0 2px 0px t('colour-c');
  }

  line-height: 0;
  padding: 10px 10px;
  border-radius: 3px;
  font-size: 16px;
}

.light-btn-link {
  text-decoration: underline !important;
  cursor: pointer;

  &:hover {
    @include themedNoEncapsulation() {
      color: t('colour-w') !important;
    }
  }

  background: none;
}

.light-btn-link-accent {
  @include themedNoEncapsulation() {
    color: t('light-accent');
  }

  &:hover {
    @include themedNoEncapsulation() {
      color: t('colour-b') !important;
    }
  }
}

.light-btn-link-accent-invert {
  @include themedNoEncapsulation() {
    color: t('colour-b');
  }

  &:hover {
    @include themedNoEncapsulation() {
      color: t('light-accent') !important
    }

    ;
  }
}

.swipeable-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  overflow-x: scroll;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    left: 80%;
  }
}

.filters-checkbox-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 10px;
}

.light-form-list {
  list-style-type: circle;
  padding: 0 0 0 25px;

  li {
    padding: 5px 0;
  }
}

.light-icon-button {
  i {
    @include themedNoEncapsulation() {
      color: t('colour-d');
    }

    font-size: 16px;
    font-weight: bold;
  }
}

.light-icon-inline {
  display: inline-block;
  vertical-align: middle;
}

.light-icon-small {
  width: 10px;
  height: 10px;
}

.light-color-primary {
  @include themedNoEncapsulation() {
    color: t('light-primary') !important;
  }
}

.light-color-warning {
  @include themedNoEncapsulation() {
    color: t('light-warning') !important;
  }
}

.light-color-info {
  @include themedNoEncapsulation() {
    color: t('colour-g') !important;
  }
}

.clearfix::after {
  clear: both;
  content: ' ';
  display: table;
}

/* TAB STYLES */

.light-tabs {
  display: flex;
}

.light-tab {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px 3px 0 0;
  display: inline-block;

  @include themedNoEncapsulation() {
    color: rgba(t('colour-font-secondary'), 0.8);
  }

  cursor: pointer;
  font-size: 9pt;
  font-weight: 700;
  margin: 0 1px 0 0;
  position: relative;
  width: 142px;
  min-height: 38px;
  line-height: 38px;
  padding: 0 0 0 18px;

  &.light-tab-active {
    background: rgba(0, 0, 0, 0.3);

    @include themedNoEncapsulation() {
      color: t('colour-font-secondary');
    }
  }
}
.reset-password-dialog-container {
  transform: translate(-50%, -50%);
  .mat-mdc-dialog-container {
    padding: 5px;
  }
}

.dropdown-menu {
  ngb-datepicker-navigation-select > .form-select {
    font-size: .87em;
    height: 1.85em;
  }

  .custom-select {
    font-size: 14px !important;
  }
}

.modal-lg {
  max-width: 1100px;
  width: 1100px;
}

.modal-large .modal-dialog{
  max-width: 768px;
}

.modal-extra-large .modal-dialog{
  max-width: 80vw;
  @media(max-width: 850px) {
    max-width: none;
  }
}

.modal-flight-extras .modal-dialog{
  max-width: 630px;
  @media(max-width: 850px) {
    max-width: none;
  }
}

/* TABLE STYLES FOR RESPONSIVE TABLES */

.light-table-values {
  display: flex;
  justify-content: space-around;

  &:nth-child(odd) {
    @include themedNoEncapsulation() {
      background: t('light-muted');
    }
  }
}

.light-table-headers {
  display: flex;
  justify-content: space-around;
}

.light-table-headers div,
.light-table-values div {
  flex: 1;
  padding: 10px;
}

/*heart START*/
.preferred-container {
  width: 100%;
  justify-content: flex-start;
  padding: 1%;
  align-items: center;
  flex: 0 1 40%;
}

.heart {
  @include themedNoEncapsulation() {
    color: t('colour-d');
  }

  font-size: 2.3rem;
  margin: $padding-a;

  &-img {
    max-height: 2.5rem;
    width: auto;
    margin: $padding-a;
  }
}

/*heart END*/

/* RIBBON */

.light-ribbon {
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;

  span {
    font-size: 10px;
    font-weight: bold;

    @include themedNoEncapsulation() {
      color: t('colour-font-secondary');
    }

    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #79a70a;
    background: linear-gradient(#9bc90d 0%, #79a70a 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 18px;
    left: -21px;

    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid #79a70a;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #79a70a;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: -1;
      border-left: 3px solid transparent;
      border-right: 3px solid #79a70a;
      border-bottom: 3px solid transparent;
      border-top: 3px solid #79a70a;
    }
  }

  &.preferred-2 {
    span {
      @include themedNoEncapsulation() {
        background: t('ribbon-platinum');
      }

      &:before {
        @include themedNoEncapsulation() {
          border-left: 3px solid darken(t(ribbon-silver), 30%);
        }

        @include themedNoEncapsulation() {
          border-top: 3px solid darken(t(ribbon-silver), 30%);
        }
      }

      &:after {
        @include themedNoEncapsulation() {
          border-right: 3px solid darken(t(ribbon-silver), 30%);
        }

        @include themedNoEncapsulation() {
          border-top: 3px solid darken(t(ribbon-silver), 30%);
        }
      }
    }
  }

  &.preferred-3 {
    span {
      @include themedNoEncapsulation() {
        background: t(ribbon-gold);
      }

      &:before {
        @include themedNoEncapsulation() {
          border-left: 3px solid darken(t(ribbon-gold), 30%);
        }

        @include themedNoEncapsulation() {
          border-top: 3px solid darken(t(ribbon-gold), 30%);
        }
      }

      &:after {
        @include themedNoEncapsulation() {
          border-right: 3px solid darken(t(ribbon-gold), 30%);
        }

        @include themedNoEncapsulation() {
          border-top: 3px solid darken(t(ribbon-gold), 30%);
        }
      }
    }
  }

  &.preferred-4 {
    span {
      @include themedNoEncapsulation() {
        background: t(ribbon-silver);
      }

      &:before {
        @include themedNoEncapsulation() {
          border-left: 3px solid darken(t(ribbon-silver), 10%);
        }

        @include themedNoEncapsulation() {
          border-top: 3px solid darken(t(ribbon-silver), 10%);
        }
      }

      &:after {
        @include themedNoEncapsulation() {
          border-right: 3px solid darken(t(ribbon-silver), 30%);
        }

        @include themedNoEncapsulation() {
          border-top: 3px solid darken(t(ribbon-silver), 30%);
        }
      }
    }
  }

  &.preferred-5 {
    span {
      @include themedNoEncapsulation() {
        background: t(ribbon-bronze);
      }

      &:before {
        @include themedNoEncapsulation() {
          border-left: 3px solid darken(t(ribbon-bronze), 30%);
        }

        @include themedNoEncapsulation() {
          border-top: 3px solid darken(t(ribbon-bronze), 30%);
        }
      }

      &:after {
        @include themedNoEncapsulation() {
          border-right: 3px solid darken(t(ribbon-bronze), 30%);
        }

        @include themedNoEncapsulation() {
          border-top: 3px solid darken(t(ribbon-bronze), 30%);
        }
      }
    }
  }
}

.light-expansion-panel.mat-expansion-panel {
  @include themedNoEncapsulation() {
    background: rgba(t('colour-container-background'), 0.3);
  }

  border-radius: 3px;

  .mat-expansion-panel-body {
    padding: 20px;
  }

  &:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  .mat-expansion-panel-header {
    border-radius: 3px;

    &:hover {
      @include themedNoEncapsulation() {
        background: rgba(t('colour-container-background'), 0.3) !important;
      }
    }
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-indicator:after {
    @include themedNoEncapsulation() {
      color: t('colour-font-secondary') !important;
    }
  }
}

.results-mode {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 3px;

  @include themedNoEncapsulation() {
    color: t('colour-b');
  }
}

.dropdown-item {
  clear: both;
  width: 100%;
  height: 38px;
  border: none;
  border-bottom: 1px solid gainsboro;
  background: none;
  text-align: left;
  padding-left: 20px;

  &:hover {
    @include themedNoEncapsulation() {
      background: t('colour-d');
    }

    @include themedNoEncapsulation() {
      color: t('colour-button-text');
    }
  }

  &.active {
    @include themedNoEncapsulation() {
      background: t('colour-d');
    }

    @include themedNoEncapsulation() {
      color: t('colour-button-text');
    }

    font-weight: bold;
  }

  @media screen and (-ms-high-contrast: active) {

    &:hover,
    &.active {
      border: 2px solid #1aebff !important;
      background: initial;
      color: #1aebff;
    }
  }
}


.ngb-dp-arrow-btn {
  @include themedNoEncapsulation() {
    color: t('colour-d') !important;
  }
}

/* STICKY HEADER DIRECTIVE */
.sticky-block {
  position: relative;

  &--sticky {
    z-index: $z-index-d;
  }
}

@media screen and (-ms-high-contrast: active) {
  a {
    color: yellow;
    text-decoration: underline !important;
  }

  .detail-accordion {
    color: yellow;
  }
}

.dismiss {
  display: none !important;
}


.cookie-law .cookie-law-wrapper .myDismiss {
  display: block;
  box-sizing: border-box;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 10px;
  text-decoration: none;
  line-height: 20px;
  cursor: pointer;
}

.myDismiss svg {
  display: block;
  fill: rgb(255, 255, 255) !important;
  width: 40px !important;
  height: 40px !important;
  -webkit-transition: fill .2s;
  transition: fill .2s;
}

.myDismiss svg:hover {
  fill: rgb(141, 141, 141) !important;
  -webkit-transition: fill .2s;
  transition: fill .2s;
}

.errored_search_container .error-box {
  border: 2px solid #f04e48 !important;
  border-radius: 0.3rem;
}

.back-to-map-link {
  @include themedNoEncapsulation() {
    color: t('colour-font-secondary') !important;
  }
}

.modal_button_okay {
  @include themedNoEncapsulation() {
    background: t('colour-d');
  }
}

.previous_button {
  @include themedNoEncapsulation() {
    color: t('light-primary') !important;
  }
}


.home-logo {
  @include themedNoEncapsulation() {
    background-image: t('home-logo');
  }

  color: red;
}
.homepage {
  margin-top: 70px;
  width: 100%;
  height: 100%;
  background-color: white;
}

img[src$='#home-logo']{
  width: 10rem;
  height: 7.5rem;
  margin: 0 auto 5rem;
  display: block;
  overflow: hidden;
  text-indent: -1000rem;
}

.header_cell--enb {
  @include themedNoEncapsulation() {
    background-color: t('flight-class-header');
    color: t('flight-class-header-font');
  }
}

.header_cell--er {
  @include themedNoEncapsulation() {
    background-color: t('flight-class-header');
    color: t('flight-class-header-font');
  }
}

.header_cell--ef {
  @include themedNoEncapsulation() {
    background-color: t('flight-class-header');
    color: t('flight-class-header-font');
  }
}


.busJourney{
  color: rgb(233, 20, 20);
}

#pushActionRefuse{
  color:#0069DC!important;
}

#pushActionRefuse:focus,#pushConfirmation:focus{
  outline: 2px solid black !important;
  box-shadow: 0px 0px 4px black !important;
}

@media(max-width: 400px) {
  #beamerPushModal {
    top: 5px;
    max-height: calc(100vh - 10px);
    overflow-y: auto;
  }
}

.margin-right-5{
  margin-right: 5px;
}
