$themes: (
  default:
    (
      light-primary: #19514e,
      light-accent: #0b7fa9,
      light-warning: red,
      light-error: rgb(255, 44, 44),
      light-transparent: rgba(0, 0, 0, 0.612),
      light-transparent-light: rgba(0, 0, 0, 0.2),
      light-transparent-darken: rgba(0, 0, 0, 0.745),
      light-muted: rgb(240, 240, 240),
      light-gray: #e5eae6,
      light-fancy: #d57600,
      wcag-grey: #747474,
      ribbon-gold: rgb(179, 161, 62),
      ribbon-platinum: linear-gradient(#b6bac9 0%, #808080 100%),
      ribbon-silver: silver,
      ribbon-bronze: rgb(139, 114, 75),
      colour-a:black,
      colour-b:white,
      colour-c:grey,
      colour-d: #33766f,
      colour-e:#19514e,
      colour-f:#2c3e50,
      colour-euro-1: #33766f,
      colour-euro-2:#19514e,
      colour-euro-3:#2c3e50,
      colour-g:#d57600,
      colour-h:#3b3b3b,
      colour-i:#ebecef,
      colour-j: #9b9b9b,
      colour-k:#f3f5fa,
      colour-l:#f5a623,
      colour-m:#fefce9,
      colour-n: #52536f,
      colour-o: #da2d2d,
      colour-p:#ab7318,
      colour-s: #c9d0d7,
      colour-r: #fbfbfe,
      colour-t: #cdced6,
      colour-w: #5ed323,
      colour-alert:red,
      colour-disabled:grey,
      colour-disabled-light: #cccccc,
      colour-container-background:black,
      colour-header-background:black,
      colour-font-primary:black,
      colour-font-secondary:white,
      colour-font-hover:white,
      colour-font-tertiary:#33766f,
      colour-font-selected: white,
      colour-log-on: #c2c2c2,
      background: url("https://images.sabscorp.com/images/enterprise/lightUK/assets/images/bg_img.jpg"),
      sub-header: linear-gradient(90deg, #213c29 0%, #24513d 20%, #0b4f64 80%, #004868 100%),
      home-logo: url("https://images.sabscorp.com/images/enterprise/lightUK/assets/images/lightning_logo.svg"),
      flight-class-header: #b36200,
      rail-tickets-background: rgba(0, 0, 0, .3),
      selected-rail-price: #2b5956,
      details-button-fill: #48094f,
      details-button-border: #48094f,
      hotel-list-detail: #f2f2f2,
      colour-button-text: white,
      colour-announcements-count: #33766f,
      colour-secondary-nav: rgba(0, 0, 0, .44),
      colour-toggle-background: black,
      result-list-item-outline: 0,
      colour-help-button: #33766f,
      toggle-button-border: none,
      flight-class-header-font: white,
      hotel-selected-rate: rgba(154, 184, 69, .2),
      colour-sort-filter: #222222!important,
      colour-button-hover: #429a90,
      seethrough-background: true,
      nav-colour: white,
      ship-marine: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Ship - Marine white.png"),
      rig-offshore: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Rig - Offshore White.png"),
      ship-marine-dark: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Ship - Marine.png"),
      rig-offshore-dark: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Rig - Offshore.png"),
      colour-nav-opions: #89949c,
      basket-item-background:transparent,
      colour-button-contrast: white,
      overtaken:yellow,
      info-icon-colour: #666,
      fare-rules-link-text: #B8CEFF,
      colour-line: #33766f,
      ctm-blue: #0077B3,
      table-border: rgba(128, 128, 128, 0.2)
    ),
  light:
    (
      light-primary: #fcbd42,
      light-accent: #0b7fa9,
      light-warning: red,
      light-error: rgb(255, 44, 44),
      light-transparent: white,
      light-transparent-light: rgba(0, 0, 0, 0.2),
      light-transparent-darken: rgba(0, 0, 0, 0.745),
      light-muted: rgb(240, 240, 240),
      light-gray: #e5eae6,
      light-fancy: #fba908,
      wcag-grey: #747474,
      ribbon-gold: rgb(179, 161, 62),
      ribbon-platinum: linear-gradient(#b6bac9 0%, #808080 100%),
      ribbon-silver: silver,
      ribbon-bronze: rgb(139, 114, 75),
      colour-a:#333,
      colour-b:white,
      colour-c:grey,
      colour-d: #9ab845,
      colour-e:#9ab845,
      colour-f:#2c3e50,
      colour-euro-1: #9ab845,
      colour-euro-2:#9ab845,
      colour-euro-3:#2c3e50,
      colour-g:#d57600,
      colour-h:white,
      colour-i:#444444,
      colour-j: #9b9b9b,
      colour-k:#f3f5fa,
      colour-l:#f5a623,
      colour-m:#fefce9,
      colour-n: #52536f,
      colour-o: #da2d2d,
      colour-p:#ab7318,
      colour-s: #c9d0d7,
      colour-r: #fbfbfe,
      colour-t: #cdced6,
      colour-w: #5ed323,
      colour-alert:red,
      colour-disabled:lightgrey,
      colour-disabled-light: #cccccc,
      colour-container-background:white,
      colour-header-background:white,
      colour-font-primary:#333,
      colour-font-secondary:white,
      colour-font-hover:white,
      colour-font-tertiary:#33766f,
      colour-font-selected: white,
      colour-log-on: #333,
      background: #eef2f5,
      sub-header: #eef2f5,
      home-logo: url("https://images.sabscorp.com/images/enterprise/lightUK/assets/images/lightning_logo_dark.svg"),
      flight-class-header: white,
      rail-tickets-background: white,
      selected-rail-price: #9ab845,
      //This is part of the base details-button-fill: white,
      details-button-border: #9ab845,
      //This is part of the base 
      hotel-list-detail: white,
      colour-button-text: #333,
      colour-announcements-count: #9ab845,
      colour-secondary-nav: rgba(255, 255, 255, .44),
      colour-toggle-background: white,
      result-list-item-outline: 0,
      colour-help-button: #9ab845,
      toggle-button-border: none,
      flight-class-header-font: #333,
      hotel-selected-rate: rgba(154, 184, 69, .2),
      colour-sort-filter: invalid,
      colour-button-hover: #429a90,
      seethrough-background: true,
      nav-colour: #333,
      ship-marine: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Ship - Marine.png"),
      rig-offshore: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Rig - Offshore.png"),
      ship-marine-dark: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Ship - Marine.png"),
      rig-offshore-dark: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Rig - Offshore.png"),
      colour-nav-opions: #89949c,
      colour-button-contrast: white,
      overtaken:red,
      info-icon-colour: white,
      fare-rules-link-text: #B8CEFF,
      colour-line: #33766f,
      ctm-blue: #0077B3,
      table-border: rgba(128, 128, 128, 0.2)
    ),
  blue-theme:
    (
      logo-font-primary: #444444,
      light-primary:rgb(0, 48, 67),
      light-accent: #0b7fa9,
      light-warning: red,
      light-error: rgb(255, 44, 44),
      light-transparent: #eef2f5,
      light-transparent-light: rgba(0, 0, 0, 0.2),
      light-transparent-darken: rgba(0, 0, 0, 0.745),
      light-muted: rgb(240, 240, 240),
      light-gray: #e5eae6,
      light-fancy: rgb(0, 48, 67),
      wcag-grey: #747474,
      ribbon-gold: rgb(179, 161, 62),
      ribbon-platinum: linear-gradient(#b6bac9 0%, #808080 100%),
      ribbon-silver: silver,
      ribbon-bronze: rgb(139, 114, 75),
      colour-a:#333,
      colour-b:white,
      colour-c:grey,
      colour-d: rgb(0, 48, 67),
      colour-e:rgb(0, 48, 67),
      colour-f:#2c3e50,
      colour-euro-1: #016162,
      colour-euro-2:#7D0552,
      colour-euro-3:#082567,
      colour-g:#d57600,
      colour-h:white,
      colour-i:white,
      colour-j: #9b9b9b,
      colour-k:#f3f5fa,
      colour-l:#db7c00,
      colour-m:#fefce9,
      colour-n: #52536f,
      colour-o: #da2d2d,
      colour-p:#ab7318,
      colour-s: #c9d0d7,
      colour-r: #fbfbfe,
      colour-t: #cdced6,
      colour-w: #006dbd,
      colour-alert:red,
      colour-disabled:lightgrey,
      colour-disabled-light: #cccccc,
      colour-container-background:#dcdcdc,
      colour-header-background:rgb(0, 48, 67),
      colour-font-primary:#333,
      colour-font-secondary:#333,
      colour-font-hover:#dcdcdc,
      colour-font-tertiary:rgb(0, 48, 67),
      colour-font-selected: white,
      colour-log-on: #333,
      background: white,
      sub-header: white,
      home-logo: url("https://images.sabscorp.com/images/enterprise/lightUK/assets/images/lightning_logo_dark.svg"),
      flight-class-header: rgb(0, 48, 67),
      rail-tickets-background: white,
      selected-rail-price: rgb(0, 48, 67),
      details-button-fill: white,
      details-button-border: rgb(0, 48, 67),
      hotel-list-detail: white,
      colour-button-text: white,
      colour-announcements-count: #33766f,
      colour-secondary-nav: lighten(rgb(0, 48, 67), 8%),
      colour-toggle-background: white,
      result-list-item-outline: 0 0 0 1px rgb(0, 48, 67),
      colour-help-button: #33766f,
      toggle-button-border: 1px solid rgb(0, 48, 67),
      flight-class-header-font: white,
      hotel-selected-rate: rgba(190, 190, 190, .2),
      colour-sort-filter: #DCDCDC!important,
      colour-button-hover: #33766f,
      seethrough-background: true,
      nav-colour: white,
      ship-marine: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Ship - Marine.png"),
      rig-offshore: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Rig - Offshore.png"),
      ship-marine-dark: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Ship - Marine.png"),
      rig-offshore-dark: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Rig - Offshore.png"),
      colour-nav-opions: #89949c,
      colour-button-contrast: white,
      overtaken:red,
      info-icon-colour: white,
      fare-rules-link-text: #B8CEFF,
      colour-line: #006dbd,
      ctm-blue: #0077B3,
      table-border: rgba(128, 128, 128, 0.6)
    ),
  navy-theme:
    (
      light-primary:#19514e,
      light-accent: #0b7fa9,
      light-warning: red,
      light-error: rgb(255, 44, 44),
      light-transparent: #eef2f5,
      light-transparent-light: rgba(0, 0, 0, 0.2),
      light-transparent-darken: rgba(0, 0, 0, 0.745),
      light-muted: rgb(240, 240, 240),
      light-gray: #e5eae6,
      light-fancy: #19514e,
      wcag-grey: #747474,
      ribbon-gold: rgb(179, 161, 62),
      ribbon-platinum: linear-gradient(#b6bac9 0%, #808080 100%),
      ribbon-silver: silver,
      ribbon-bronze: rgb(139, 114, 75),
      colour-a:#333,
      colour-b:white,
      colour-c:grey,
      colour-d: #19514e,
      colour-e:#19514e,
      colour-f:#2c3e50,
      colour-euro-1: #19514e,
      colour-euro-2:#19514e,
      colour-euro-3:#2c3e50,
      colour-g:#d57600,
      colour-h:white,
      colour-i:white,
      colour-j: #9b9b9b,
      colour-k:#f3f5fa,
      colour-l:#f5a623,
      colour-m:#fefce9,
      colour-n: #52536f,
      colour-o: #da2d2d,
      colour-p:#ab7318,
      colour-s: #c9d0d7,
      colour-r: #fbfbfe,
      colour-t: #cdced6,
      colour-w: #006dbd,
      colour-alert:red,
      colour-disabled:lightgrey,
      colour-disabled-light: #cccccc,
      colour-container-background:#dcdcdc,
      colour-header-background:rgb(0, 48, 67),
      colour-font-primary:#333,
      colour-font-secondary:#333,
      colour-font-hover:#dcdcdc,
      colour-font-tertiary:rgb(0, 48, 67),
      colour-font-selected: white,
      colour-log-on: #333,
      background: white,
      sub-header: white,
      home-logo: url("https://images.sabscorp.com/images/enterprise/lightUK/assets/images/lightning_logo_dark.svg"),
      flight-class-header: rgb(0, 48, 67),
      rail-tickets-background: white,
      selected-rail-price: rgb(0, 48, 67),
      details-button-fill: white,
      details-button-border: rgb(0, 48, 67),
      hotel-list-detail: white,
      colour-button-text: white,
      colour-announcements-count: #33766f,
      colour-secondary-nav: lighten(rgb(0, 48, 67), 8%),
      colour-toggle-background: black,
      result-list-item-outline: 0 0 0 1px rgb(0, 48, 67),
      colour-help-button: #33766f,
      toggle-button-border: 1px solid rgb(0, 48, 67),
      flight-class-header-font: white,
      hotel-selected-rate: rgba(190, 190, 190, .2),
      colour-sort-filter: #003043!important,
      colour-button-hover: #33766f,
      seethrough-background: false,
      nav-colour: white,
      ship-marine: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Ship - Marine.png"),
      rig-offshore: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Rig - Offshore.png"),
      ship-marine-dark: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Ship - Marine.png"),
      rig-offshore-dark: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Rig - Offshore.png"),
      colour-nav-opions: white,
      colour-button-contrast: white,
      overtaken:red,
      info-icon-colour: white,
      fare-rules-link-text: #B8CEFF,
      colour-line: #006dbd,
      ctm-blue: #0077B3,
      table-border: rgba(128, 128, 128, 0.6)
    ),
  scion:
    (
      logo-font-primary: #444444,
      light-primary:rgb(0, 48, 67),
      light-accent: #0b7fa9,
      light-warning: red,
      light-error: rgb(255, 44, 44),
      light-transparent: #eef2f5,
      light-transparent-light: rgba(0, 0, 0, 0.2),
      light-transparent-darken: rgba(0, 0, 0, 0.745),
      light-muted: rgb(240, 240, 240),
      light-gray: #e5eae6,
      light-fancy: rgb(0, 48, 67),
      wcag-grey: #747474,
      ribbon-gold: rgb(179, 161, 62),
      ribbon-platinum: linear-gradient(#b6bac9 0%, #808080 100%),
      ribbon-silver: silver,
      ribbon-bronze: rgb(139, 114, 75),
      colour-a:#333,
      colour-b:white,
      colour-c:grey,
      colour-d: rgb(0, 48, 67),
      colour-e:rgb(0, 48, 67),
      colour-f:#2c3e50,
      colour-euro-1: #016162,
      colour-euro-2:#7D0552,
      colour-euro-3:#082567,
      colour-g:#d57600,
      colour-h:white,
      colour-i:white,
      colour-j: #9b9b9b,
      colour-k:#f3f5fa,
      colour-l:#db7c00,
      colour-m:#fefce9,
      colour-n: #52536f,
      colour-o: #da2d2d,
      colour-p:#ab7318,
      colour-s: #c9d0d7,
      colour-r: #fbfbfe,
      colour-t: #cdced6,
      colour-w: #006dbd,
      colour-alert:red,
      colour-disabled:lightgrey,
      colour-disabled-light: #cccccc,
      colour-container-background:#dcdcdc,
      colour-header-background:rgb(0, 48, 67),
      colour-font-primary:#333,
      colour-font-secondary:#333,
      colour-font-hover:#dcdcdc,
      colour-font-tertiary:rgb(0, 48, 67),
      colour-font-selected: white,
      colour-log-on: #333,
      background: white,
      sub-header: white,
      home-logo: url("https://images.sabscorp.com/images/enterprise/lightUK/assets/images/lightning_logo_dark.svg"),
      flight-class-header: rgb(0, 48, 67),
      rail-tickets-background: white,
      selected-rail-price: rgb(0, 48, 67),
      details-button-fill: white,
      details-button-border: rgb(0, 48, 67),
      hotel-list-detail: white,
      colour-button-text: white,
      colour-announcements-count: #33766f,
      colour-secondary-nav: lighten(rgb(0, 48, 67), 8%),
      colour-toggle-background: white,
      result-list-item-outline: 0 0 0 1px rgb(0, 48, 67),
      colour-help-button: #33766f,
      toggle-button-border: 1px solid rgb(0, 48, 67),
      flight-class-header-font: white,
      hotel-selected-rate: rgba(190, 190, 190, .2),
      colour-sort-filter: #DCDCDC!important,
      colour-button-hover: #33766f,
      seethrough-background: true,
      nav-colour: white,
      ship-marine: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Ship - Marine.png"),
      rig-offshore: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Rig - Offshore.png"),
      ship-marine-dark: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Ship - Marine.png"),
      rig-offshore-dark: url("https://images.sabscorp.com/images/enterprise/lightUK/specialfares/Rig - Offshore.png"),
      colour-nav-opions: #89949c,
      colour-button-contrast: white,
      overtaken:red,
      info-icon-colour: white,
      fare-rules-link-text: #B8CEFF,
      colour-line: #006dbd,
      ctm-blue: #0077B3,
      table-border: rgba(128, 128, 128, 0.6)
    )
);

// MEDIA QUERIES
$mobile: "(max-width: 767px)";
$tablet: "(min-width: 768px) and (max-width: 1023px)";
$desktop: "(min-width: 1024px)";

//initial variables for Lightning-catalogue page
//Now live - please do not alter!a

//$background-image-a:"images/tesco2.jpg";
$background-image-a: "https://images.sabscorp.com/images/enterprise/lightUK/assets/images/bg_img.jpg";

$font-family-a: Open Sans, sans-serif;

// quaternary
// quinary
// senary
// septenary
// octonary
// nonary
// denary

$radius-a: 0.3rem;
$radius-b: 0.6rem;
$radius-c: 0.9rem;

$font-size-a: 1.4rem;
$font-size-b: 1.5rem;
$font-size-c: 2rem;
$font-size-d: 2.5rem;

$font-size-e: $font-size-a;
$font-size-f: $font-size-a * 0.9;
$font-size-g: 12px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;

$line-height-a: 1.35rem;
$line-height-b: 1.5rem;
$line-height-c: 2rem;
$line-height-d: 2.835rem;
$line-height-e: 3.4rem;
$line-height-f: 7rem;
$line-height-g: 7.8rem;

$padding-a: 0.5rem;

$border-width-a: 1px;
$border-width-b: 2px;

//The column layouts currently only have one break point.
$screen-a: 767px;

$spacer-a: 15px;

$mobile-body-margin: 5px;
$tablet-body-margin: 15px;

$width-a: 9rem;
$width-b: 12rem;
$width-c: 15rem;
$width-d: 18rem;
$width-e: 20rem;

$height-a: 1rem;
$height-b: 1.5rem;
$height-c: 17px;
$height-d: 2.5rem;

//These two ratios must add up to 1
//the gap between the two containers is taken from the spacer value
$ui-ratio-a: 0.75;
$ui-ratio-b: 0.25;
//

$ui-width-a: 1600px;
$ui-width-b: 1200px;
$ui-width-c: 1000px;
$ui-width-d: 560px;
$ui-width-ipad: 768px;

$z-index-a: 10;
$z-index-b: 20;
$z-index-c: 30;
$z-index-d: 40; //default header bar
$z-index-e: 50;
$z-index-f: 60;
$z-index-g: 70;
$z-index-h: 99;
$z-index-i: 999;

@mixin themedNoEncapsulation() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}
@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} :host & {
      $theme-map: () !global;

      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}
@mixin themedNgDeep() {
  @each $theme, $map in $themes {
    ::ng-deep.theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}
@function t($key) {
  @return map-get($theme-map, $key);
}
