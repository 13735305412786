[class*=' ico-m-']:before,
[class^=ico-m-]:before,
.ico-material:before {
    font-family: 'Material Icons' !important;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.ico-list:before {
    content: "#";
    font-size: 25px;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.ico-m-parking:before,
.ico-parking:before {
    content: 'local_parking';
}

.ico-m-place:before,
.ico-place:before {
    content: 'place';
}

.ico-m-lounge:before,
.ico-lounge:before {
    content: 'local_bar';
}

.ico-m-euro:before,
.ico-euro:before {
    content: 'euro_symbol';
}

.ico-m-fast-track:before,
.ico-fast-track:before {
    content: 'fast_forward';
}

.ico-m-season-ticket:before,
.ico-season-ticket:before {
    content: 'confirmation_number';
}