@import 'variables';

/*GLOBAL INPUTS BOXES*/
.light-form-section {
  border: 1px solid gainsboro;
  padding: 10px;
  border-radius: 3px;
  margin: 10px 0;
  clear:both;
  flex: 1 1 100%;
}

.light-input {
  border-radius: 3px;
  padding: 6px 8px;
  font-size: 14px;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.light-validate {
  .ng-invalid.ng-touched {
    // border-color: $light-error;
    // box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
    // background-color: rgba(255, 0, 0, 0.059);
  }
}

.light-validate-hard {
  .ng-invalid {
    // border-color: $light-error;
    // box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
    // background-color: rgba(255, 0, 0, 0.059);
  }
}


.bg-primary {
  @include themed(){background-color: t('colour-d') !important;}
  font-weight: bold;
}

.light-input-group {
  margin: 10px 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  label {
    width: 100%;
  }
  & > i:first-of-type {
    position: absolute;
    left: 10px;
    top: 30px;
    color: #333;
   }
  & > i ~ i:last-of-type {
    position: absolute;
    right: 0;
    top: 5px;
   }
  & > i.light-spinner {
    position: absolute;
    left: initial;
    right: 33px;
    top: 32px;
  }
}

.light-form-group {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap; /*Needed due to inputs going out of bounds on MI modal (phone nos)*/
}

.light-input-group-accent {
  border: 0;
  margin: 0 10px;
  flex: 1;
  box-shadow: none;
  .light-input, .light-select {
    margin: 0;
    border: none;
  }
}

.light-input-group .light-input,
.light-input-group .light-select {
  border-radius: 0;
  flex: 1;
  &:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-of-type:not(:last-child) {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.modal_label {
  color: black !important;
}

.countryNoInput{
  flex: 1 1 40px !important;
}

.areaNoInput{
  flex: 1 1 100px !important;
}

.phoneNoInput{
  flex: 1 1 150px !important;
}

.extensionNoInput{
  flex: 1 1 70px !important;
}

.mobilePhoneNoInput{
  flex: 1 1 360px !important;
}

.light-input-btn {
  border-radius: 0 3px 3px 0;
  padding-left: 5px;
  padding-right: 5px;
}

.light-btn.light-input-btn {
  border-radius: 0 3px 3px 0;
  padding: 8px 5px;
}

.light-input-has-ico {
  padding-left: 30px;
}

.light-select {
  font-size: 100%;
  padding: 6px 8px;
  height: auto !important;
  -webkit-appearance:none;
	@include themed(){background: t('colour-b') url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAG0lEQVR42mNgwAfKy8v/48I4FeA0AacVDFQBAP9wJkE/KhUMAAAAAElFTkSuQmCC) no-repeat 95% 50%;}
}

/* MAT PRIMARY STYLES */
.light-primary {
  @media screen and (-ms-high-contrast: active) {
    &.mat-mdc-checkbox-checked{
      -ms-high-contrast-adjust:none;
    }
    &.mat-mdc-checkbox:focus{
      -ms-high-contrast-adjust:none;
    }
  }
  .mat-mdc-radio-checked .mdc-radio__outer-circle, .mdc-radio__outer-circle {
    @include themed(){border-color: t('colour-d')!important;}
    -ms-high-contrast-adjust:none;
  }
  .mat-mdc-checkbox-frame {
    @include themed(){border-color: t('colour-d');}
    -ms-high-contrast-adjust:none;
  }
  .mdc-radio__inner-circle {
    @include themed(){background-color: t('colour-d')!important;}
    -ms-high-contrast-adjust:none;
  }
}

.light-accent {
  .mdc-radio__outer-circle {
    @include themed(){border-color: t('colour-d');}
    -ms-high-contrast-adjust:none;
  }
  .mat-mdc-checkbox-frame {
    @include themed(){border-color: t('colour-d');}
    -ms-high-contrast-adjust:none;
  }
}

.light-primary,
.light-accent {
  &.mat-mdc-radio-button {
    min-width: 100px;
  }
}

/* DATE SELECTORS */
.light-datepickers {
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  align-items: center;
  label {
    text-align: left;
  }
  .light-date-group {
    flex: 1;
    margin: 0 10px;
  }
}
.light-date-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  .light-date-input,
  input {
    height: 40px;
    width: 100%;
    line-height: 40px;
    border: 0.1rem solid #d5d5d5;
    @include themed(){background: t('colour-b');}
    font-size: 14px;
    @include themed(){color: t('colour-f');}
    border-radius: 4px;
    padding-left: 26px;
    &:focus,
    &:active {
      background: rgb(250, 250, 250);
    }
  }
  .light-date-addon,
  i {
    padding-left: 7px;
    left: 0px;
    top: 11px;
    position: absolute;
  }
  :first-child:not(input) {
    margin-right: 5px;
  }
  @media (max-width: 1367px) {
    width: 100%;
    margin: 5px 0;
    &:first-child:not(input) {
      margin-right: 0;
    }
  }
}
